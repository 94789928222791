import Vue from "vue";

/**
 * display the word "punkty" in the correct variation, depending on the number
 */
Vue.filter("points", function (v){
     v = Number(v);
     v = v % 1000;//the rest of the division by 1000
     v = v % 100;//the rest of the division by 100
     let label = 'punktów';
     if(Number(v) == 1 ){
         label = 'punkt';
     } else if (
         [2, 3, 4].includes(v)
         || (
            v > 21 //bigger than
            && [2, 3, 4].includes(v % 10) //and the rest of the division by 10
         )
     )
     {
         label = 'punkty';
     }

     return label;
});

