
export default {
    namespaced: true,
    state: {
        goals_and_results: null,
        widget: null
    },
    mutations: {
        setGoalsAndResults(state, payload) {
            state.goals_and_results = payload;
        },
        setGoalsAndResultsWidget(state, payload) {
            state.widget = payload;
        },
    },
    actions: {
        async getGoalsAndResults({commit}, payload) {
            try {
                let path = '/goals-and-results';
                if(payload.distributor_id !== undefined && payload.distributor_id !== null){
                    path += '?distributor_id=' + payload.distributor_id;
                } else if(payload.department_id !== undefined && payload.department_id !== null){
                    path += '?department_id=' + payload.department_id;
                }
                const response = await window.api.get(path);
                commit('setGoalsAndResults', response.data.data)
            } catch(err) {
                await this.$store.dispatch('popup/showError', {
                    type: 'err',
                    title: 'Niepoprawne dane',
                    body: err
                });
            }
        },
        async getGoalsAndResultsWidget({commit}, payload) {
            try {
                let path = '/goals-and-results/widget';
                if(payload.distributor_id !== undefined && payload.distributor_id !== null){
                    path += '?distributor_id=' + payload.distributor_id;
                } else if(payload.department_id !== undefined && payload.department_id !== null){
                    path += '?department_id=' + payload.department_id;
                }
                const response = await window.api.get(path);
                commit('setGoalsAndResultsWidget', response.data.data)
            } catch(err) {
                await this.$store.dispatch('popup/showError', {
                    type: 'err',
                    title: 'Niepoprawne dane',
                    body: err
                });
            }
        }
    },
    getters: {
        getGoalsAndResults(state) {
            return state.goals_and_results;
        },
        getGoalsAndResultsWidget(state) {
            return state.widget;
        }
    }
}
