
export default {
    namespaced: true,
    state: {
        news: null,
        newsLength: '',
        newsHome: []
    },
    mutations: {
        setNews(state, payload) {
            state.news = payload;
        },
        setNewsHome(state, payload) {
            state.newsHome = payload;
        },
        setNewsLength(state, payload) {
            state.newsLength = payload;
        }
    },
    actions: {
        async getNews({commit}) {
            try {
                const response = await window.api.get('/news');
                commit('setNews', response.data.data)
                commit('setNewsLength', response.data.data.length);
            } catch(err) {
                await this.$store.dispatch('popup/showError', {
                    type: 'err',
                    title: 'Niepoprawne dane',
                    body: err
                });
            }
        },
        async getNewsHome({commit}){
            try {
                const response = await window.api.get('/news/widget');
                commit('setNewsHome', response.data.data)
            } catch(err) {
                await this.$store.dispatch('popup/showError', {
                    type: 'err',
                    title: 'Niepoprawne dane',
                    body: err
                });
            }
        }
    },
    getters: {
        getNews(state) {
            return state.news;
        },
        getNewsHome(state) {
            return state.newsHome;
        },
        getNewsLength(state) {
            return state.newsLength;
        }
    }
}
