import router from "../../router";

export default {
    namespaced: true,
    state: {
        isLoggedIn: !!sessionStorage.getItem('access_token'),
        sessionId: sessionStorage.getItem('access_token'),
        user: {

        }
    },
    mutations: {
        userUpdate(state, payload) {
            state.user = payload;
        },
        setLoggedIn(state, payload) {
            state.isLoggedIn = payload;
        },
        setSessionId(state, payload) {
            state.sessionId = payload;
        },
    },
    actions: {
        login({ commit }, payload) {
            return new Promise((resolve, reject) => {
                window.api.post('/login', payload)
                    .then((response) => {
                        sessionStorage.setItem('access_token', response.data.data.token);
                        commit('setLoggedIn', true);
                        // dispatch('getUser');
                        router.go(history && history.length > 2 ? -1 : {name: 'home'});
                        resolve(response);
                    })
                    .catch((e) => {
                        if (sessionStorage.getItem('access_token')) {
                            sessionStorage.removeItem('access_token')
                        }
                        reject(e);
                    })
            })
        },
        logout: function ({commit}) {
            sessionStorage.removeItem('access_token');
            commit('setLoggedIn', false);
            commit("setSessionId", '');
            router.replace({name: 'login'})
        },
        async getUser({commit}) {
            try {
                const response = await window.api.get('/me');
                commit('userUpdate', response.data.data)
            } catch(err) {
                await this.$store.dispatch('popup/showError', {
                    type: 'err',
                    title: 'Niepoprawne dane',
                    body: err
                });
            }

            this.isSending = false;
        }
    },
    getters: {
        isLoggedIn(state) {
            return !!state.isLoggedIn;
        },
        getSessionId(state) {
            return state.sessionId;
        },
        getUserInfo(state) {
            return state.user;
        },
    }
}
