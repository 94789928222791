<template>
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                        <div>
                            <p class="f16 mb-2">Masz pytania? Zadzwoń na infolinię <span class="fw700 color-main">801 800 099.</span></p>
                            <p class="f12 mb-0">Czynna od pn. do pt. w godz. 9:00 - 17:00</p>
                        </div>
                        <ul class="footer__list mb-0">
                            <li>
                                <a class="f16 color-main fw700" href="assets/app/files/Zasady_przetwarzania_danych_osobowych_FZ_final.pdf" target="_blank">Polityka prywatności</a>
                            </li>
                            <li class="d-none d-lg-block">
                                <div class="f16 color-text">|</div>
                            </li>
                            <li>
                                <a class="f16 color-main fw700" href="assets/app/files/Regulamin_konkursu_Formula_Zyskow_z_Aneksem_2.pdf" target="_blank">Regulamin strony</a>
                            </li>
                            <li class="d-none d-lg-block">
                                <div class="f16 color-text">|</div>
                            </li>
                            <li>
                                <a class="f16 color-main fw700" href="assets/app/files/PLIKI_COOKIES_FZ_FINAL_04.04.2022.pdf" target="_blank">Cookies</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent"
}
</script>

<style lang="scss">
    .footer {
        background-color: rgba(43, 82, 161, 0.1);
        padding: 2rem 0;

        &__list {
            list-style: none;
            padding: 0;
            display: flex;
            align-items: center;

            @include media-breakpoint-down(lg) {
                flex-direction: column;
            }

            @include media-breakpoint-down(sm) {
                margin-top: 2rem;
            }

            li {
                &:not(:last-of-type) {
                    margin-right: 5px;
                }
                a {
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
</style>
