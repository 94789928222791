import axios from 'axios'
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
// axios.defaults.headers.common['Access-Control-Request-Headers'] = '*';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

// axios.defaults.baseURL = process.env.APP_URL ||  '';


const api = axios.create({
    // baseURL: (window.apiUrl && window.apiUrl !== '') ? window.apiUrl : "/api",
    baseURL: '/api',
})



api.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem("access_token");
        if (token) {
            config.headers.common["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
//
// api.interceptors.response.use(
//     response => {
//         if (response.status === 200 || response.status === 201) {
//             return Promise.resolve(response);
//         } else {
//             return Promise.reject(response);
//         }
//     },
//     error => {
//         if (error.response.status) {
//             switch (error.response.status) {
//                 case 401:
//                 case 403:
//                     sessionStorage.clear();
//                     delete axios.defaults.headers.common['Authorization'];
//                     window.location.reload();
//                     break;
//             }
//         }
//         return Promise.reject(error);
//     }
// );

export default api;
