
export default {
    namespaced: true,
    state: {
        stages: null,
        widgetRank: null
    },
    mutations: {
        setStages(state, payload) {
            state.stages = payload;
        },
        setWidgetRank(state, payload) {
            state.widgetRank = payload;
        },
    },
    actions: {
        async getStages({commit}) {
            try {
                const response = await window.api.get('/stages');
                commit('setStages', response.data.data)
            } catch(err) {
                await this.$store.dispatch('popup/showError', {
                    type: 'err',
                    title: 'Niepoprawne dane',
                    body: err
                });
            }
        },
        async getWidgetRnk({commit}, payload) {
            try {
                let path = '/stage-ranking/widget';
                if(payload.distributor_id !== undefined && payload.distributor_id !== null){
                    path += '?distributor_id=' + payload.distributor_id;
                } else if(payload.department_id !== undefined && payload.department_id !== null){
                    path += '?department_id=' + payload.department_id;
                }
                const response = await window.api.get(path);
                commit('setWidgetRank', response.data.data)
            } catch(err) {
                await this.$store.dispatch('popup/showError', {
                    type: 'err',
                    title: 'Niepoprawne dane',
                    body: err
                });
            }
        }
    },
    getters: {
        getStages(state) {
            return state.stages;
        },
        getWidgetRank(state) {
            return state.widgetRank;
        }
    }
}
