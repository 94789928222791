const defaultPayload = {};
export default {
    namespaced: true,
    state: {
        visible: false,
        payload: defaultPayload,
    },

    mutations: {
        showPopup(state, payload){
            state.visible = true;
            state.payload = {...state.payload, ...payload};
            document.querySelector('body').classList.add('no-scroll');
        },
        hidePopup(state){
            state.visible = false;
            state.payload = defaultPayload;
        }
    },

    actions: {
        showError(context, payload) {
            if(payload.body.response.data) {
                payload = payload.body.response.data;
                if (payload.errors && payload.errors.length > 0) {
                    let msg = '';
                    Object.keys(payload.errors).forEach((key) => {
                        if (payload.errors[key].length > 1) {
                            payload.errors[key].forEach(el => {
                                msg += el + "<br>"
                            });
                        } else {
                            msg += payload.errors[key][0] + "<br>";
                        }
                    });
                    context.commit('showPopup', {
                        type: 'err',
                        title: 'Błąd!',
                        body: msg,
                    });
                } else if (payload.message) {
                    context.commit('showPopup', {
                        type: 'err',
                        title: 'Błąd!',
                        body: payload.message,
                    });
                }
            }else{
                console.log('payload msg', payload)
                context.commit('showPopup', {
                    type: 'error',
                    size: 'normal',
                    title: 'Błąd!',
                    body: 'Coś poszło nie tak.',
                });
            }

        }
    }

};
