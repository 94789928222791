<template>

   <div :class="['popup', type, visible ? 'active' : '']">
       <div class="popup__bg" @click="close"/>
       <div class="popup__content">
           <div class="popup__close" @click="close"/>
           <p v-if="title !== ''" class="popup__title">{{ title }}</p>
           <div class="popup__text" v-html="body" />
       </div>
   </div>

</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: "PopupComponent",
        computed: mapState({
            visible: state => state.popup.visible,
            type: state => state.popup.payload.type,
            title: state => state.popup.payload.title,
            body: state => state.popup.payload.body,
        }),
        methods: {
            close() {
                this.$store.commit('popup/hidePopup');
                document.querySelector('body').classList.remove('no-scroll');
            }
        }
    }
</script>

