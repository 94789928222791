export const routes = [
    {
        path: '/',
        name: 'home',
        meta: { requiresAuth: true },
        component: () => import('../views/HomeView.vue')
    },
    {
        path: '/l098098098/:token',
        name: 'autologin',
        component: () => import('../components/modules/auth/AutologinComponent.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        meta: { requiresAuth: true },
        component: () => import('../views/ContactView.vue')
    },
    {
        path: '/faq',
        name: 'faq',
        meta: { requiresAuth: true },
        component: () => import('../views/FaqView.vue')
    },
    {
        path: '/edit-account',
        name: 'editAccount',
        meta: { requiresAuth: true },
        component: () => import('../views/EditAccountView.vue')
    },
    {
        path: '/elearning',
        name: 'elearning',
        meta: { requiresAuth: true },
        component: () => import('../views/LearningView')
    },
    {
        path: '/goals-and-results',
        name: 'goalsAndResults',
        meta: { requiresAuth: true },
        component: () => import('../views/GoalsAndResultsView')
    },
    {
        path: '/news',
        name: 'news',
        meta: { requiresAuth: true },
        component: () => import('../views/NewsView.vue')
    },
    {
        path: '/news/:id',
        name: 'singleNews',
        meta: { requiresAuth: true },
        component: () => import('../views/news/SingleNewsView.vue')
    },
    {
        path: '/rules',
        name: 'rules',
        meta: { requiresAuth: true },
        component: () => import('../views/RulesView.vue')
    },
    {
        path: '/prizes',
        name: 'prizes',
        meta: { requiresAuth: true },
        component: () => import('../views/PrizesViews.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../components/modules/auth/LoginComponent.vue')
    },
    {
        path: '/login-remind',
        name: 'loginRemind',
        component: () => import('../components/modules/auth/LoginRemindComponent.vue')
    },
    {
        path: '/reset-password/:token',
        name: 'resetPassword',
        component: () => import('../components/modules/auth/ResetPasswordComponent.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../components/modules/auth/RegisterComponent.vue')
    },
    {
        path: '/register-success',
        name: 'registerSuccess',
        component: () => import('../components/modules/auth/AuthRegisterSuccess.vue')
    },
    {
        path: '/login-remind-success',
        name: 'loginRemindSuccess',
        component: () => import('../components/modules/auth/AuthLoginRemindSuccess')
    },
    {
        path: '/verify/:id/:token',
        name: 'accountVerify',
        component: () => import('../components/modules/auth/AccountVerify.vue')
    },
    {
        path : '*',
        name: 'error-404',
        component: () => import('../components/error/Error404.vue')
    }
]
