<template>
    <transition name="fade">
        <div v-if="show" class="cookies">
            Używamy plików cookies (tzw. „ciasteczka”) w&nbsp;celu optymalizacji korzystania ze stron internetowych, tworzenia statystyk. Możesz wyłączyć ten mechanizm w&nbsp;dowolnym momencie w&nbsp;ustawieniach przeglądarki.
            <button type="button" @click="hide">Zamknij</button>
        </div>
    </transition>
</template>

<script>
export default {
    name: "CookieComponent",
    data: function(){
        return {
            show: false
        }
    },
    mounted() {
        this.init();
    },
    methods:{
        init(){
            if( !localStorage.getItem('formulazyskow2') ){
                this.show = true;
            }
        },
        hide(){
            localStorage.setItem('formulazyskow2', true);
            this.show = false;
        }
    }
}
</script>
