import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import Vuelidate from 'vuelidate';
import VueDOMPurifyHTML from "vue-dompurify-html";
import VueYoutube from "vue-youtube";
import VuejsDialog from "vuejs-dialog";
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
Vue.use(VuejsDialog)
Vue.use(Vuelidate);
Vue.use(VueDOMPurifyHTML);
Vue.use(VueYoutube);

import 'bootstrap/dist/js/bootstrap.min';

Vue.config.productionTip = false

import api from './helpers/api';
window.api = api;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
