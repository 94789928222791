import Vue from 'vue'
import Vuex from 'vuex'
import VueDOMPurifyHTML from 'vue-dompurify-html'

Vue.use(Vuex)
Vue.use(VueDOMPurifyHTML)

import goal_and_result from './states/goal_and_result';
import news from './states/news';
import popup from './states/popup';
import stage from './states/stage';
import user from './states/user';

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
      goal_and_result,
      news,
      popup,
      stage,
      user,
  }
})
