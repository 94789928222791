<template>
    <div>
        <popup-slot-component
            v-if="showPopup"
            type="data"
            :height="'750px'"
            :title="'Zgody i oświadczenia'"
            @onClick="$store.dispatch('user/logout')"
        >
            <h3 class="f16 fw700 text-center text-uppercase color-green mt-5 mb-5">Aby kontynuować należy zaakceptować wymagane pole i&nbsp;kliknąć w&nbsp;przycisk "Biorę udział".</h3>
            <form class="register-box__form" @submit.prevent="agree">
                <div class="row text-start">
                    <div class="col-12 px-4">
                        <div class="check">
                            <input id="arg2" type="checkbox" value="arg2" v-model="$v.form.agreement2.$model" :class="{'error': $v.form.agreement2.$error && send || errors.agreement_rules}">
                            <label class="f16" for="arg2"><span></span>
                                <div v-html="regulations.rules"></div>
                            </label>
                        </div>
                        <div v-if="$v.form.agreement2.$dirty && send">
                            <p class="error-text f12" v-if="!$v.form.agreement2.sameAs">Zgoda wymagana</p>
                        </div>
                        <div v-if="errors.agreement_rules">
                            <p class="error-text f12" v-for="(err, i) in errors.agreement_rules" :key="err + i">{{ err }}</p>
                        </div>
                    </div>
                </div>
                <div class="row text-start">
                    <div class="col-12 px-4">
                        <p class="f12">
                            Zaznaczając powyższe oświadczenie oraz klikając przycisk "Biorę udział", wyrażasz zgodę na przetwarzanie swoich danych osobowych przez „Hochland Polska” Sp.&nbsp;z o.o. w&nbsp;celu przeprowadzenia Konkursu, w&nbsp;tym realizacji postanowień jego Regulaminu. Zgoda ta może zostać w&nbsp;każdej chwili wycofana poprzez przesłanie stosownej informacji na adres e-mail <a class="link blue-dark fw800" href="mailto:daneosobowe@hochland.com">daneosobowe@hochland.com</a> lub za pośrednictwem konta Uczestnika utworzonego w&nbsp;związku z&nbsp;udziałem w&nbsp;Konkursie. Wycofanie zgody nie wpływa na zgodność z&nbsp;prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.
                        </p>
                        <p class="f12">
                            Administratorem Twoich danych osobowych jest „Hochland Polska” Sp.&nbsp;z o.o. z&nbsp;siedzibą w&nbsp;Kaźmierzu, ul.&nbsp;Okrężna&nbsp;2, 64-530 Kaźmierz, kontakt: <a class="link blue-dark fw800" href="mailto:daneosobowe@hochland.com">daneosobowe@hochland.com</a>.
                        </p>
                        <p class="f12">
                            Jeśli uzyskasz nagrodę lub złożysz reklamację, Twoje dane będą przetwarzane w&nbsp;celu wydania i&nbsp;rozliczenia tej nagrody zgodnie z&nbsp;obowiązującymi przepisami prawa lub udzielenia odpowiedzi na Twoją reklamację. W&nbsp;tym przypadku administratorem Twoich danych jest organizator konkursu – Albedo Marketing ul.&nbsp;Góralska&nbsp;3, 60-623 Poznań (konta: <a class="link blue-dark fw800" href="mailto:dane@albedomarketing.pl">dane@albedomarketing.pl</a>).
                        </p>
                        <p class="f12">
                            Informacje o&nbsp;zasadach przetwarzania danych osobowych przez ww. administratorów, w&nbsp;tym o&nbsp;przysługujących Ci prawach wynikających z&nbsp;RODO dostępne są <a class="link blue-dark fw800" target="_blank" href="assets/app/files/Regulamin_konkursu_Formula_Zyskow_z_Aneksem_2.pdf">w&nbsp;załączniku do regulaminu konkursu</a>.
                        </p>
                    </div>
                </div>
                <div class="row text-end">
                    <div class="col-12 px-4 my-5">
                        <div class="d-flex justify-content-center">
                            <button class="btn" type="submit"><span v-if="isSending" class="loader"></span><span :style="{opacity: !isSending ? '1' : '0'}">Biorę udział</span></button>
                        </div>
                    </div>
                </div>
            </form>
        </popup-slot-component>
    </div>
</template>

<script>
import PopupSlotComponent from "@/components/modules/PopupSlotComponent";
import {sameAs} from 'vuelidate/lib/validators';
import _ from 'lodash';

export default {
    name: "AgreementsComponent",
    data() {
        return {
            isSending: false,
            send: false,
            form: {
                agreement2: false
            },
            errors: {},
            regulations: {
                rules: ''
            },
            showedPopup: true
        }
    },
    computed:{
        showPopup: function (){
            let user = this.$store.getters['user/getUserInfo'];
            if(!this.hasDistributorRole(user) && user.regulations_confirmed !== undefined && !user.regulations_confirmed && this.showedPopup){
                return true;
            } else {
                return false;
            }
        }
    },
    components: {PopupSlotComponent},
    validations: {
        form: {
            agreement2: {
                sameAs: sameAs(() => true)
            },
        }
    },
    mounted() {
        this.getRegisterData();
    },
    methods:{
        scrollToFirstError() {
            this.$nextTick(() => {
                let domRect = document.querySelector('.error').getBoundingClientRect();
                console.log('domrect', domRect)
                window.scrollTo(
                    domRect.left + document.documentElement.scrollLeft,
                    domRect.top + document.documentElement.scrollTop - 50
                );
            })
        },
        hasDistributorRole: function (user){
            if(user.roles !== undefined && user.roles.length>0){
                let roleDistribution = _.find(user.roles, function (value){
                    return value.name=='distributor';
                })
                if(roleDistribution!==undefined){
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        async getRegisterData() {
            const response = await window.api.get('/register');
            this.regulations.rules = response.data.data.regulations.rules;
        },
        async agree() {
            this.$v.$touch();
            this.send = true;
            if (this.$v.$invalid) {
                this.scrollToFirstError();
            } else {
                this.isSending = true;

                try {
                    await window.api.put('/me/regulations-confirmed');

                    this.isSending = false;
                    setTimeout(()=>{
                        this.$store.dispatch('user/getUser');
                    }, 100);

                } catch(err) {
                    this.isSending = false;

                    if (err.response.data.status === 'success') {
                        this.errors = err.response.data.errors;
                        console.log('errors', this.errors);
                        this.scrollToFirstError();
                    }
                    if (err.response.data.status === 'error') {
                        this.errors = err.response.data.errors;
                        await this.$store.dispatch('popup/showError', {
                            type: 'err',
                            title: 'Niepoprawne dane',
                            body: err
                        });
                    }
                }

                this.isSending = false;
            }
        }
    }
}
</script>

<style scoped>

</style>
