<template>
  <div id="app" class="app" :class="{'bg-app': backgroundImage}">
      <div>
          <navigation-component v-if="isLoggedIn"/>
          <transition>
              <transition name="move-down" mode="out-in">
                  <router-view/>
              </transition>
          </transition>
          <popup-component></popup-component>
          <cookie-component></cookie-component>
      </div>
      <footer-component v-if="isLoggedIn"></footer-component>
      <transition>
          <transition name="move-down" mode="out-in">
            <agreements-component v-if="isLoggedIn"></agreements-component>
          </transition>
      </transition>
  </div>
</template>

<script>

import PopupComponent from "@/components/modules/PopupComponent";
import NavigationComponent from "@/components/modules/NavigationComponent";
import FooterComponent from "@/components/modules/FooterComponent";
import CookieComponent from "@/components/modules/CookieComponent";
import AgreementsComponent from "@/components/modules/auth/AgreementsComponent";
import "./filters/filters";
export default {
    name: 'App',
    components: {
        AgreementsComponent,
        NavigationComponent,
        PopupComponent,
        FooterComponent,
        CookieComponent
    },
    data() {
        return {
            backgroundImage: false,
        }
    },
    watch: {
        $route(to) {
            if (to.name !== 'login' && to.name !== 'loginRemind' && to.name !== 'resetPassword') {
                this.backgroundImage = true;
            } else {
                this.backgroundImage = false;
            }
        }
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters['user/isLoggedIn']
        }
    }
}
</script>

<style lang="scss">
    @import "src/styles/app";
</style>
