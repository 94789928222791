<template>
   <div :class="['popup', type, 'active']">
       <div class="popup__bg" @click="close"/>
       <div class="popup__content" :style="{'height': height}">
           <div class="popup__close" @click="close"/>
           <p v-if="title !== ''" class="popup__title">{{ title }}</p>
           <div class="popup__text">
               <vue-custom-scrollbar class="scroll-area" :settings="scrollbarSettings">
                  <slot></slot>
               </vue-custom-scrollbar>
           </div>
       </div>
   </div>

</template>

<script>
    import vueCustomScrollbar from 'vue-custom-scrollbar'
    import "vue-custom-scrollbar/dist/vueScrollbar.css"
    export default {
        name: "PopupSlotComponent",
        data() {
            return {
                scrollbarSettings: {
                    suppressScrollY: false,
                    suppressScrollX: true,
                    wheelPropagation: true
                }
            }
        },
        props: {
            /**
             * `err`, `data`, `ok`
             */
            type: {
              type: String,
              default: 'ok'
            },
            title: String,
            height:{
                type: String,
                default: 'auto'
            }
        },
        components:{
            vueCustomScrollbar
        },
        methods: {
            close() {
                this.$emit('onClick');
                document.querySelector('body').classList.remove('no-scroll');
            }
        }
    }
</script>

