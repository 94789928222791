<template>
    <section class="nav">
        <img class="nav__logo-hochland" src="@/img/logo-hochland.png" alt="Logo hochland">
        <div class="navigation container">
            <div class="row align-items-center" style="row-gap: 20px;">
                <div class="col-md-4 col-xl-5 navigation__logo">
                    <router-link :to="{name: 'home'}">
                        <img src="@/img/logo-formula.svg" alt="Formuła Zysków" class="navigation__logo-img"/>
                    </router-link>
                </div>
                <div class="col-sm-6 col-md-5 fw700 text-center text-sm-left text-md-end">
                    <span class="f20 mb-1">Witaj ponownie, {{user.first_name}}!</span><br/>
                    <div class="f14 mb-1">Sprawdź swoje aktualne wyniki</div>

                    <div v-if="hasDistributorRole" class="input">
                        <span class="">Wybierz oddział: </span>
                        <select class="distributor_department-box__select d-inline-block w-50"
                                name="distributor"
                                id="distributor"
                                v-model="selectedDistributorDepartment"
                        >
                            <option disabled value="">Wybierz oddział</option>
                            <template v-for="distributor in distributors" >
                                <optgroup v-if="distributor.departments.length>0" :label="distributor.name" :key="distributor.id">
                                    <option v-for="department in distributor.departments" :value="distributor.id + '_' +department.id" :key="department.id">{{department.full_name}}</option>
                                </optgroup>
                                <option v-else :value="distributor.id" :key="distributor.id">{{distributor.name}}</option>
                            </template>
                        </select>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 col-xl-2 d-flex align-items-center justify-content-center justify-content-sm-end" style="column-gap: 32px;">
                    <div class="navigation-menu">
                        <div class="navigation-menu__toggler" @click="openMenu = !openMenu" :class="{open: openMenu}">
                            <div class="navigation-menu__toggler-bar bar-1"></div>
                            <div class="navigation-menu__toggler-bar bar-2"></div>
                            <div class="navigation-menu__toggler-bar bar-3"></div>
                            <span class="navigation-menu__toggler-text">MENU</span>
                        </div>
                        <div class="navigation-menu__wrapper" :class="{open: openMenu}">
                            <div class="navigation-menu__scrollable">
                                <div class="navigation-menu__list">
                                    <router-link :to="{name: 'home'}">Strona główna</router-link>
                                    <router-link :to="{name: 'news'}">Aktualności</router-link>
                                    <router-link :to="{name: 'rules'}">Zasady</router-link>
                                    <router-link :to="{name: 'prizes'}">Nagrody</router-link>
                                    <router-link :to="{name: 'goalsAndResults'}">Cele i wyniki</router-link>
                                    <router-link v-if="user && user.elearningTime" :to="{name: 'elearning'}" class="navigation-menu__list-elearning">Szkolenie</router-link>
                                    <router-link v-else :to="{name: 'elearning'}">Szkolenie</router-link>
                                    <router-link :to="{name: 'faq'}">FAQ</router-link>
                                    <router-link :to="{name: 'contact'}">Kontakt</router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="account-menu">
                        <div class="account-menu__toggler">
                            <img src="@/img/user-icon.svg"/>
                        </div>
                        <div class="account-menu__list">
                            <span class="link" @click="editData">Edytuj dane</span>
                            <span class="divider"></span>
                            <span class="link" @click="logout">Wyloguj się</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
import _ from 'lodash';
export default {
    name: "NavigationComponent",
    data() {
        return {
            openMenu: false,
            selectedDistributorDepartment: null,
        }
    },
    computed:{
        user: function (){
            return this.$store.getters["user/getUserInfo"]
        },
        distributors: function (){
            if(this.user.distributor !== undefined && this.user.distributor.id!==undefined){
                return [this.user.distributor];
            } else {
                return [];
            }
        },
        hasDistributorRole: function (){
            if(this.user.roles !== undefined && this.user.roles.length>0){
                let roleDistribution = _.find(this.user.roles, function (value){
                    return value.name=='distributor';
                })
                if(roleDistribution!==undefined){
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        hasRepresentantiveRole: function (){
            if(this.user.roles !== undefined && this.user.roles.length>0){
                let roleDistribution = _.find(this.user.roles, function (value){
                    return value.name=='representative';
                })
                if(roleDistribution!==undefined){
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    },
    watch:{
        distributors: function (distributors){
            if(this.selectedDistributorDepartment == null){
                if(this.hasDistributorRole){
                    if(distributors[0].departments !== undefined && distributors[0].departments.length>0){
                        this.selectedDistributorDepartment = distributors[0].id + '_' + distributors[0].departments[0].id;
                    } else {
                        this.selectedDistributorDepartment = distributors[0].id;
                    }
                }
            }
        },
        selectedDistributorDepartment: function (distributorDepartment){
            let distributor_id = null;
            let department_id = null;
            if(Number.isInteger(distributorDepartment)){
                distributor_id = distributorDepartment;
            } else {
                const distr_and_dep = distributorDepartment.split('_');
                department_id = distr_and_dep[1];
            }
            this.$store.dispatch('goal_and_result/getGoalsAndResults', {distributor_id: distributor_id, department_id: department_id});
            this.$store.dispatch('goal_and_result/getGoalsAndResultsWidget', {distributor_id: distributor_id, department_id: department_id});
            this.$store.dispatch('stage/getWidgetRnk', {distributor_id: distributor_id, department_id: department_id});
        },
        hasRepresentantiveRole: function (hasRepresentantiveRole){
            if(hasRepresentantiveRole){
                this.$store.dispatch('goal_and_result/getGoalsAndResults', {});
                this.$store.dispatch('goal_and_result/getGoalsAndResultsWidget', {});
                this.$store.dispatch('stage/getWidgetRnk', {});
            }
        }
    },
    created() {
        const self = this;
        document.addEventListener('click', function (e) {
            if (!e.target.classList.value.includes('navigation-menu')) {
                self.openMenu = false;
            }
        })
    },
    mounted() {
        this.$store.dispatch('user/getUser');
    },
    methods: {
        logout() {
            this.$store.dispatch('user/logout');
        },
        editData() {
            this.$router.push({name: 'editAccount'})
        }
    }
}
</script>

<style lang="scss">
.distributor_department-box__select{
    background: transparent !important;
    text-overflow: ellipsis !important;
    cursor: pointer;
}
.nav {
    &__logo-hochland {
        max-width: 257px;
        width: 100%;
        position: absolute;
        top: -10px;
        left: -10px;

        @include media-breakpoint-down(xl) {
            max-width: 180px
        }

        @media screen and (max-width: 1125px) {
            max-width: 130px
        }
    }
}
.navigation {
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 20px;

    @include media-breakpoint-up(lg) {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &__logo {
        text-align: center;

        @media screen and (max-width: 1450px) {
            text-align: right;
        }
    }

    &-menu {
        &__list-elearning {
            position: relative;
            &::before {
                content: url('~@/img/star.svg');
                position: absolute;
                left: 10px;
                top: 47%;
                transform: translateY(-50%);
            }

            &::after {
                content: url('~@/img/star.svg');
                position: absolute;
                left: 160px;
                top: 47%;
                transform: translateY(-50%);
            }
        }
    }

    &__logo-img {
        max-width: 255px;
        width: 100%;

        @media screen and (max-width: 940px) {
            max-width: 180px;
        }
    }
}

.account-menu {
    position: relative;
    display: inline-block;
    z-index: 2;

    &:hover {
        .account-menu {
            &__toggler {
                background-image: linear-gradient(#76A709, #005724);
                box-shadow: 0 8px 24px rgba(0, 87, 36, 0.48);
            }

            &__list {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__toggler {
        width: 48px;
        height: 48px;
        background-image: linear-gradient(#347BC7, #001378);
        box-shadow: 0 8px 24px rgba(28, 91, 177, 0.48);
        border-radius: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
        z-index: 2;
    }

    &__list {
        opacity: 0;
        visibility: hidden;
        transition: .2s;
        position: absolute;
        top: calc(100% + 20px);
        right: 0;
        padding: 12px 14px;
        background-color: #FFF;
        border-radius: 4px;
        filter: drop-shadow(0 8px 16px rgba(0, 19, 120, 0.48));
        white-space: nowrap;
        display: flex;

        &:before {
            content: '';
            position: absolute;
            top: -12px;
            right: 12px;
            width: 24px;
            height: 12px;
            border: 12px solid transparent;
            border-top-width: 0;
            border-bottom-color: #FFF;
        }

        .link {
            font-weight: bold;
            color: $color-main;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                color: $color-green;
            }
        }

        .divider {
            margin: 0 8px;
            border-left: 1px solid #D4E7F4;
            border-radius: 8px;
        }
    }
}

.navigation-menu {
    display: inline-block;
    z-index: 2;

    &__toggler {
        cursor: pointer;
        text-align: center;
        z-index: 2;
        position: relative;

        &-bar {
            width: 40px;
            height: 4px;
            border-radius: 3px;
            background-color: $color-blue-dark;
            margin-bottom: 6px;
            transition: .2s;
            margin-left: auto;
        }

        span {
            display: block;
            font-size: 13px;
            line-height: 1;
            font-weight: 900;
            color: $color-blue-dark;
            transition: .2s;
        }

        &:hover {
            .bar-2 {
                width: 32px;
            }

            .bar-3 {
                width: 24px;
            }

            span {
                color: $color-main;
            }
        }

        &.open {
            .bar-1 {
                transform: translateY(10px) rotate(45deg);
            }

            .bar-2 {
                opacity: 0;
            }

            .bar-3 {
                width: 100%;
                transform: translateY(-10px) rotate(-45deg);
            }
        }
    }

    &__wrapper {
        position: fixed;
        width: 390px;
        max-width: 95vw;
        height: 768px;
        max-height: 100vh;
        top: 0;
        right: -100%;
        opacity: 0;
        visibility: hidden;
        transition: .3s;
        background: linear-gradient(rgba(255, 255, 255, 0.9), #DEF2FF);
        box-shadow: -8px 0 32px rgba(0, 19, 120, 0.48);
        padding: 190px 0 30px;
        display: flex;
        align-items: flex-start;

        @include media-breakpoint-down(sm) {
            padding-top: 250px;
        }

        &.open {
            right: 0;
            opacity: 1;
            visibility: visible;
            z-index: 1;
        }
    }

    &__scrollable {
        width: 100%;
        max-height: 100%;
        overflow: auto;
    }

    &__list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-height: 100%;

        a {
            font-size: 24px;
            font-weight: bold;
            color: $color-main;
            padding: 8px 0 8px 36px;
            text-decoration: none;
            transition: .2s;

            &:hover {
                color: $white;
                background-color: $color-main;
            }

            &.active {
                border-left: 4px solid $color-main;
            }
        }
    }
}

</style>
